
import { useState } from 'react';
import { observer } from 'mobx-react';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';
import { useHenshuApp } from '@strategies/henshu-app';

import NavSection from '../NavSection';
import { ENGAGEMENT_LINK } from '../../config';
const ENGAGEMENT_LINKS = [
    'https://sasaki.sharefile.com/public/share/web-s4d2d05ac74af4a29a655aaf0d4adab47',
    'https://sasaki.sharefile.com/public/share/web-s50e6ee0c46bc4cb49461b1037566d9e7'
];

export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);
    const { persist, isEditing } = useHenshuApp();
    return (
        <div className="Welcome-wrap">
            <NavSection name="Welcome" index={0}>
                <Anchor onChange={(anchor) => setPromptHidden(anchor.placement === 'offscreen')} />
                <div className='logo'></div>
                <div className='link-info'><henshu.richtext {...bindTo('enegage.calendar.infobodyupdate')} /></div>
                <div className="links">
                    
                     
                   

                    <henshu.a
                        className="button with-arrow"
                        href={ENGAGEMENT_LINKS[0]}
                        target={"_blank"}
                        {...bindTo('enegage.welcome.button.plansummary')}
                    />
                    <henshu.a
                        className="button with-arrow"
                        href={ENGAGEMENT_LINKS[1]}
                        target={"_blank"}
                        {...bindTo('enegage.welcome.button.fullreport')}
                    />
                    </div>
            </NavSection>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
